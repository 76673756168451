@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);
/* Scrollbar styles */
.scrollbar-container {
  position: relative;
  height: 100%;
}

.ps {
  overflow: hidden;
  touch-action: auto;
}

.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  bottom: 0px;
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  right: 0;
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  bottom: 2px;
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  right: 2px;
  position: absolute;
}

:root {
  --white-color: white;
  --black-color: black;

  --text-primary-color: #121c39;
  --text-secondary-color: #5d698a;

  --cl-primary-color: #5e9ce0;
  --cl-success-color: #7dd2cc;
  --cl-primary-light-color: #e8eeff;
}

* {
  outline: none !important;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  background-color: #fbfcff;
  overflow-x: hidden;
  font-size: 16px !important;
}

a {
  font-family: 'Inter', sans-serif;
}

/* Button */
.input-group {
  margin-bottom: 15px;
}

.input-group .form-control {
  background-color: #e8eeff;
  border-radius: 100px !important;
  height: 60px;
  border: none;
  width: 100%;
  outline: none;
  box-shadow: none;
  padding-left: 23px;
  font-size: 12px;
  letter-spacing: 0.27px;
  color: #121c39;
  -webkit-filter: drop-shadow(0px 7px 10px rgba(30, 53, 134, 0.0633741));
          filter: drop-shadow(0px 7px 10px rgba(30, 53, 134, 0.0633741));
}

.input-group .input-group-append {
  position: absolute;
  right: 0;
  z-index: 9;
  height: 60px;
  display: flex;
  align-items: center;
  padding-right: 6px;
}
.input-group .input-group-append .btn {
  height: 50px;
  background: linear-gradient(245.22deg, #d8e9f5 -123.43%, #6fadf1 57.13%);
  border-radius: 22.5px !important;
  border: none;
  padding: 0 22px;
  outline: none;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }

  .row > .col,
  .row > [class*='col-'],
  .form-row > .col,
  .form-row > [class*='col-'] {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.login-btn {
  width: 148px;
}

.not-shopify-checkbox {
  padding-left: 2.5rem !important;
}

.login-input {
  margin-bottom: 16px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

h4.header-primary {
  font-size: 38px;
  font-weight: bold;
  line-height: 59px;
  letter-spacing: 0.82px;
  margin-bottom: 30px;
  color: #121c39;
  color: var(--text-primary-color);
}

.text-primary {
  font-size: 18px;
  line-height: 34px;
  color: #121c39 !important;
  color: var(--text-primary-color) !important;
  letter-spacing: 0.388043px;
  margin-bottom: 30px;
}
.text-secondary {
  font-size: 18px;
  line-height: 34px;
  color: #5d698a !important;
  color: var(--text-secondary-color) !important;
  letter-spacing: 0.388043px;
  margin-bottom: 30px;
}

/*---- Header -----*/
.header {
  -webkit-clip-path: ellipse(150% 120% at 50% -25%);
          clip-path: ellipse(150% 120% at 50% -25%);
  background-color: #ecf4ff;
  padding-bottom: 20px;
  padding-top: 151px;
}
.header .hero {
  padding-bottom: 127px;
}
.header .hero-left {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header .hero-left h1 {
  font-weight: bold;
  font-size: 46px;
  line-height: 59px;
  letter-spacing: 0.991666px;
  color: #121c39;
  color: var(--text-primary-color);
  margin-bottom: 29px;
  margin-left: 24px;
}
.header .hero-left p.description {
  font-size: 18px;
  line-height: 34px;
  letter-spacing: 0.388043px;
  color: #121c39;
  color: var(--text-primary-color);
  margin-bottom: 25px;
  margin-left: 27px;
}

.header .hero-left span {
  font-size: 14px;
  line-height: 34px;
  letter-spacing: 0.301811px;
  color: #121c39;
  color: var(--text-primary-color);
}
.header .hero-left span.text-danger {
  font-size: 14px;
  line-height: 34px;
  letter-spacing: 0.301811px;
  margin-left: 32px;
  color: #121c39;
  color: var(--text-primary-color);
}
.header .hero-brand h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 2.7px;
  text-transform: uppercase;
  color: #121c39;
  color: var(--text-primary-color);
  margin-bottom: 44px;
}
.header .hero-brand ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .hero-brand ul li {
  margin-left: 35px;
  margin-right: 35px;
}
.header .hero-brand ul li img {
  height: 23px;
}

#myVideo {
  right: 0;
  bottom: 0;
  width: 110%;
}

@media (max-width: 575.98px) {
  .header {
    padding-bottom: 120px !important;
    -webkit-clip-path: ellipse(240% 120% at 50% -25%);
            clip-path: ellipse(240% 120% at 50% -25%);
  }
  .header .hero {
    padding-bottom: 50px;
  }
  .header .hero-left h1 {
    font-size: 33px;
    line-height: 43px;
    margin-bottom: 18px;
  }
  .header .hero-left p.description {
    font-size: 16px;
    line-height: 29px;
    margin-bottom: 32px;
  }
  .header .hero-right {
    margin-left: -30px;
    margin-right: -30px;
  }
  .header .hero-right #myVideo {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
  .header .hero-brand h3 {
    margin-bottom: 41px;
  }
  .header .hero-brand ul {
    flex-wrap: wrap;
  }
  .header .hero-brand ul li {
    margin-right: 17px;
    margin-left: 17px;
    margin-bottom: 25px;
  }
  .header .hero-brand ul li:last-child {
    margin-right: 0;
  }
  .header .hero-brand ul li img {
    height: 16px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .header {
    padding-bottom: 120px !important;
    -webkit-clip-path: ellipse(240% 120% at 50% -25%);
            clip-path: ellipse(240% 120% at 50% -25%);
  }
  .header .hero {
    padding-bottom: 50px;
  }
  .header .hero-left h1 {
    font-size: 33px;
    line-height: 43px;
    margin-bottom: 18px;
  }
  .header .hero-left p.description {
    font-size: 16px;
    line-height: 29px;
    margin-bottom: 32px;
  }

  .header .hero-right {
    margin-left: -30px;
    margin-right: -30px;
  }
  .header .hero-right #myVideo {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
  .header .hero-brand h3 {
    margin-bottom: 41px;
  }
  .header .hero-brand ul {
    flex-wrap: wrap;
  }
  .header .hero-brand ul li {
    margin-right: 17px;
    margin-left: 17px;
    margin-bottom: 25px;
  }
  .header .hero-brand ul li:last-child {
    margin-right: 0;
  }
  .header .hero-brand ul li img {
    height: 16px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .header {
    padding-bottom: 120px !important;
    -webkit-clip-path: ellipse(240% 120% at 50% -25%);
            clip-path: ellipse(240% 120% at 50% -25%);
  }
  .header .hero {
    padding-bottom: 50px;
  }
  .header .hero-left h1 {
    font-size: 33px;
    line-height: 43px;
    margin-bottom: 18px;
  }
  .header .hero-left p.description {
    font-size: 16px;
    line-height: 29px;
    margin-bottom: 32px;
  }
  .header .hero-right {
    margin-left: -30px;
    margin-right: -30px;
  }
  .header .hero-right #myVideo {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
  .header .hero-brand h3 {
    margin-bottom: 41px;
  }
  .header .hero-brand ul {
    flex-wrap: wrap;
  }
  .header .hero-brand ul li {
    margin-right: 17px;
    margin-left: 17px;
    margin-bottom: 25px;
  }
  .header .hero-brand ul li:last-child {
    margin-right: 0;
  }
  .header .hero-brand ul li img {
    height: 16px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .header .hero-right #myVideo {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
}
/*----- end Header ----*/

/**
App Public Layout Style
 */

